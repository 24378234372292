import { useQuery } from "@apollo/client";
import React, { useContext,useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import SearchBox from "../../../UI/SearchBox/SearchBox";
import { GetQuestionCount, GetTestDetailById } from "../TestQuery";
import { Error } from "../../Promocode/Promocode";
import SnackbarContext from "../../../../Context/SnackbarContext";
import Empty from "../../../UI/Empty/Empty";
import { CircularProgress } from "@material-ui/core";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";
import TestPrice from "../TestPrice/TestPrice";
import TestQuestion from "../TestQuestion/TestQuestion";
import { Pagination } from "../../Report/Report";
import APP from '../../../../services/app-service'
const GLOBAL = require('../../../../services/global-constant');
const axios = require('axios').default;


const TestDetail = () => {  
    const [searchString, setSearchString] = useState<string>("");

    const [editPrice, setEditPrice] = useState<any>(null);
    const [priceModal, setPriceModal] = useState<boolean>(false);

    const [editQuestion, setEditQuestion] = useState<any>(null);
    const [questionModal, setQuestionModal] = useState<boolean>(false);
    const { setSnackbar, setMessage } = useContext(SnackbarContext);
    const [limit] = useState(7);
    const [offset, setOffset] = useState(0);

    const { id: testId } = useParams<{ id: string }>();

    const [tests, setData] = useState<any>(null);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);

    const [questionCount, setCData] = useState<any>(null);
    const [errorCount, setCError] = useState();
    const [countLoading, setCLoading] = useState(true);

    useEffect(() => {
        console.log("sdsd");
        refetch();
    },[testId,offset]);

    const refetch = async () => {
        console.log("sdsd");
        setLoading(true);
        setCLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getAdminTestDetail,{
            testId:testId,
            limit:limit,
            offset:offset,
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setData(response.data);
                setCData(response.data.qCount);
            }else{
                setError(response.data.message);
            }
            setLoading(false);
            setCLoading(false);
        })
        .catch(function (error:any) {
            console.log("error",error);
        });
	};

    // const { data: tests, error, loading, refetch } = useQuery(
    //     GetTestDetailById,
    //     {
    //         variables: {
    //             testId: parseInt(testId),
    //             searchString: `%${searchString}%`,
    //             limit,
    //             offset,
    //         },
    //     }
    // );

    // const {
    //     data: questionCount,
    //     error: errorCount,
    //     loading: countLoading,
    //     refetch: countRefetch,
    // } = useQuery(GetQuestionCount, {
    //     variables: {
    //         testId: parseInt(testId),
    //         searchString: `%${searchString}%`,
    //     },
    // });

    if (error || errorCount) {
        return <Error error={error ? error : errorCount} />;
    }

    if (!loading && tests.tests.length > 0) {
    } else if (!loading && tests.tests.length === 0) {
        setMessage("Test either does not exist or is deleted by the admin");
        setSnackbar(true);
        return (
            <div className="container-main">
                <div className="report__detail-box">
                    <Empty />
                </div>
            </div>
        );
    }

    return (
        <div className="container-main">
            <TestPrice
                isOpen={priceModal}
                setModal={setPriceModal}
                refetch={refetch}
                countRefetch={refetch}
                setEditData={setEditPrice}
                editData={editPrice}
            />

            <TestQuestion
                isOpen={questionModal}
                setModal={setQuestionModal}
                refetch={refetch}
                countRefetch={refetch}
                setEditData={setEditQuestion}
                editData={editQuestion}
            />
            <div className="w-40">
                <SearchBox
                    placeholder="Search Questions"
                    onChangeSearch={(value) => {
                        setSearchString(value);
                    }}
                />
            </div>
            <div className="test__detail-box">
                {loading || countLoading ? (
                    <div className="h-100">
                        <div className="spinner-container">
                            <CircularProgress size={40} thickness={4} />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="test__main">
                            <div className="test__header">
                                <p className="test__title">
                                    {tests.tests[0].name}
                                </p>

                                <PrimaryButton
                                    label="Test Price"
                                    disabled={false}
                                    loading={false}
                                    active
                                    onClick={() => {
                                        setPriceModal(true);
                                        setEditPrice(tests.tests[0]);
                                    }}
                                />

                                <PrimaryButton
                                    label="+Add Questions"
                                    disabled={false}
                                    loading={false}
                                    active
                                    onClick={() => {
                                        setQuestionModal(true);
                                    }}
                                />
                            </div>

                            {tests.tests[0].questions.map((element: any) => {
                                return (
                                    <QuestionItem
                                        key={element.id}
                                        element={element}
                                        onClickEdit={() => {
                                            setQuestionModal(true);
                                            setEditQuestion(element);
                                        }}
                                    />
                                );
                            })}
                        </div>

                        {!loading && !countLoading && (
                            <Pagination
                                limit={limit}
                                offset={offset}
                                count={questionCount}
                                changeOffset={(value: any) => setOffset(value)}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default TestDetail;

interface TestItemProps {
    element: any;
    onClickEdit: () => void;
}

const QuestionItem: React.FC<TestItemProps> = ({ element, onClickEdit }) => {
    return (
        <div className="test__item">
            <p className="promo__item">{element.priority}</p>
            <p className="promo__item">
                {element.question_languages[0].question}
            </p>
            <div className="test__actions">
                <button className="btn-list" onClick={onClickEdit}>
                    edit
                </button>
            </div>
        </div>
    );
};
