import React, { useContext, useEffect, useState } from "react";
import "./PromoEdit.scss";
import ReactModal from "react-modal";

import Input from "../../../UI/Input/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContext from "../../../../Context/SnackbarContext";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";
import close from "../../../../Assets/Images/cross.svg";
import { CreatePromoCode, UpdatePromoCode } from "../PromoQuery";
import { useMutation } from "@apollo/client";
import APP from '../../../../services/app-service';
const GLOBAL = require('../../../../services/global-constant');
const axios = require('axios').default;

interface PromoEditProps {
    isOpen: boolean;
    editData: any;
    setEditData: (element: any | null) => void;
    setModal: (value: boolean) => void;
    refetch: () => void;
}

const PromoEdit: React.FC<PromoEditProps> = ({
    isOpen,
    editData,
    setEditData,
    setModal,
    refetch,
    ...props
}) => {
    const [name, setName] = useState<string>("");
    const [associate, setAssociate] = useState<string>("");
    const [offer, setOffer] = useState<any>("");

    const [mutationLoading, setMutationLoading] = useState(false);

    const [insertPromoCode] = useMutation(CreatePromoCode);
    const [updatePromoCode] = useMutation(UpdatePromoCode);
    const { setSnackbar, setMessage } = useContext(SnackbarContext);

    useEffect(() => {
        if (editData) {
            setName(editData && editData.promoCode);
            setAssociate(editData && editData.associate);
            setOffer(editData && editData.offer);
        }
    }, [editData]);

    const clearState = () => {
        setName("");
        setAssociate("");
        setOffer("");
        setEditData(null);
    };

    const onSave = () => {
        setMutationLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.addPromoCodes,{
               promoCode: name,
               associate:associate,
               offer:offer
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                    setMessage("Promo Code Added Successfully");
                    setSnackbar(true);
                    refetch();
                    clearState();
                    setModal(false);
                    setMutationLoading(false);
            }else{
                setMessage("Some Unknown Error Occurred");
                setMutationLoading(false);
                return setSnackbar(true);
            }
        })
        .catch(function (error:any) {
            setMessage(error);
            setSnackbar(true);
            setMutationLoading(false);
        });

        // insertPromoCode({
        //     variables: {
        //         promoCode: name,
        //         associate,
        //         offer,
        //     },
        // })
        //     .then(({ data: { insert_promo_codes: promoCode } }: any) => {
        //         if (promoCode.affected_rows > 0) {
        //             setMessage("Promo Code Added Successfully");
        //             setSnackbar(true);
        //             refetch();
        //             clearState();
        //             setModal(false);
        //             setMutationLoading(false);
        //         } else {
        //             setMessage("Some Unknown Error Occurred");
        //             setMutationLoading(false);
        //             return setSnackbar(true);
        //         }
        //     })
        //     .catch((error) => {
        //         setMessage(error.message);
        //         setSnackbar(true);
        //         setMutationLoading(false);
        //     });
    };

    const onEdit = () => {
        setMutationLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.updatePromoCodes,{
            promoCode: name,
            associate:associate,
            offer:offer,
            id: editData.id,
     },APP.getToken())
     .then(function (response:any) {
         if(!response.data.error){
            setMessage("Promo Code Updated Successfully");
            setSnackbar(true);
            refetch();
            clearState();
            setMutationLoading(false);
            return setModal(false);
         }else{
            setMessage("Some Unknown Error Occurred");
            setMutationLoading(false);
            return setSnackbar(true);
         }
     })
     .catch(function (error:any) {
        setMessage(error.message);
        setSnackbar(true);
        setMutationLoading(false);
     });

        // updatePromoCode({
        //     variables: {
        //         promoCode: name,
        //         associate,
        //         offer,
        //         id: editData.id,
        //     },
        // })
        //     .then(({ data: { update_promo_codes: promoCode } }: any) => {
        //         if (promoCode.affected_rows > 0) {
        //             setMessage("Promo Code Updated Successfully");
        //             setSnackbar(true);
        //             refetch();
        //             clearState();
        //             setMutationLoading(false);
        //             return setModal(false);
        //         } else {
        //             setMessage("Some Unknown Error Occurred");
        //             setMutationLoading(false);
        //             return setSnackbar(true);
        //         }
        //     })
        //     .catch((error) => {
        //         setMessage(error.message);
        //         setSnackbar(true);
        //         setMutationLoading(false);
        //     });
    };

    return (
        <ReactModal
            className="add__modal Modal__main"
            onRequestClose={() => {
                clearState();
                setModal(false);
            }}
            overlayClassName="Overlay"
            isOpen={isOpen}
        >
            <div className="close">
                <img
                    src={close}
                    alt="Close"
                    className="close__img close__img-user"
                    onClick={() => {
                        clearState();
                        setModal(false);
                    }}
                />
            </div>
            <div className="add__modal__content">
                <p className="add__modal__header">
                    {editData ? "Edit Promo Code" : "Add Promo Code"}
                </p>
                <div className="margin-auto w-75">
                    <Input
                        placeholder={"Promo Code (Minimum 8 Characters)"}
                        label
                        type={"text"}
                        disabled={false}
                        value={name}
                        onChange={(event: string) => {
                            setName(event);
                        }}
                    />
                    <Input
                        placeholder={"Associate"}
                        label
                        type={"text"}
                        disabled={false}
                        value={associate}
                        onChange={(event: string) => {
                            setAssociate(event);
                        }}
                    />
                    <Input
                        placeholder={"Offer"}
                        label
                        type={"number"}
                        disabled={false}
                        value={offer}
                        onChange={(event: string) => {
                            setOffer(parseInt(event));
                        }}
                    />
                </div>

                {editData ? (
                    !mutationLoading ? (
                        <div
                            className="w-40 margin-auto"
                            style={{ marginTop: "2em" }}
                        >
                            <PrimaryButton
                                label="Edit"
                                loading={mutationLoading}
                                disabled={
                                    !(name.length > 7 && associate && offer)
                                }
                                active
                                onClick={() => {
                                    onEdit();
                                }}
                            />
                        </div>
                    ) : (
                        <div
                            className="spinner-container"
                            style={{ marginTop: "3em" }}
                        >
                            <CircularProgress size={40} thickness={4} />
                        </div>
                    )
                ) : (
                    <div
                        className="w-40 margin-auto"
                        style={{ marginTop: "2em" }}
                    >
                        <PrimaryButton
                            label="Save"
                            disabled={
                                !(
                                    name.length > 7 &&
                                    associate &&
                                    offer.toString().length > 0
                                )
                            }
                            loading={mutationLoading}
                            active
                            onClick={() => {
                                onSave();
                            }}
                        />
                    </div>
                )}
            </div>
        </ReactModal>
    );
};

export default PromoEdit;
