import { gql } from "@apollo/client";

export const GetTest = gql`
	query GetTests {
		tests(order_by: {id: asc}) {
			id
			name
		}
	}
`;


export const GetTestDetailById = gql`
	query GetTestDetailById($testId: Int!, $searchString: String!, $limit: Int!, $offset: Int!) {
		tests(where: {id: {_eq: $testId}}) {
			id
			name
			amount
			questions(order_by: {priority: asc}, where: {isDeleted: {_eq: false}, question_languages: {question: {_ilike: $searchString}}}, limit: $limit, offset: $offset) {
			priority
			id
			categoryId
			category {
				name
			}
			question_languages(order_by: {languageId: asc}) {
				id
				question
				languageId
				language {
				name
				}
			}
			}
		}
	}
`;

export const UpdateAmountByTestId = gql`
	mutation UpdateAmountByTestId($testId: Int!, $amount: Int!) {
		update_tests(where: {id: {_eq: $testId}}, _set: {amount: $amount}) {
			affected_rows
		}
	}
`


export const GetTestDetail = gql`
	query GetTestDetail1($testId: Int!) {
		languages(where: {isDeleted: {_eq: false}}, order_by: {id: asc}) {
			id
			name
		}
		categories(where: {testId: {_eq: $testId}}) {
			id
			name
		}
	}
`;


export const InsertQuestion = gql`
	mutation InsertQuestion($categoryId: Int!, $priority: Int!, $testId: Int!, $questionLanguages: [question_languages_insert_input!]!) {
		insert_questions(objects: {categoryId: $categoryId, priority:  $priority, testId: $testId, question_languages: {data: $questionLanguages}}) {
			affected_rows
		}
	}
`;


// { languageId: 10, question: "", questionId: 1 }



export const UpdateQuestion = gql`
	mutation UpdateQuestion($questionId: Int!, $categoryId: Int!, $priority: Int!, $questionLanguages:  [question_languages_insert_input!]!) {
		delete_question_languages(where: {questionId: {_eq: $questionId}}) {
			affected_rows
		}
		update_questions(where: {id: {_eq: $questionId}}, _set: {categoryId: $categoryId, priority: $priority}) {
			affected_rows
		}
		insert_question_languages(objects: $questionLanguages) {
			affected_rows
		}
	}
`;


export const GetQuestionCount = gql`
	query GetQuestionCount($testId: Int!, $searchString: String!) {
		questions_aggregate(where: {testId: {_eq: $testId}, question_languages: {question: {_ilike: $searchString}}}) {
			aggregate {
			count
			}
		}
	}
`;