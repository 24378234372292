import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext,useState,useEffect } from "react";
import { useParams } from "react-router";
import SnackbarContext from "../../../../Context/SnackbarContext";
import { parseIsoToDate } from "../../../../Utils/parseIsoToDate";
import Empty from "../../../UI/Empty/Empty";
import { ReportDetailElement } from "../../Report/ReportDetail/ReportDetail";
import { getUserById } from "../UserQuery";
import "../User.scss";
import APP from '../../../../services/app-service'
const GLOBAL = require('../../../../services/global-constant');
const axios = require('axios').default;

const UserDetail: React.FC = () => {
    const { id: userId } = useParams<{ id: string | undefined }>();
    const { setSnackbar, setMessage } = useContext(SnackbarContext);

    const [users, setUsers] = useState<any>(null);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    
    // const {
    //     data: users,
    //     error,
    //     loading,
    // } = useQuery(getUserById, {
    //     variables: { userId },
    // });

    useEffect(() => {
        setLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getUserById,{
            userId:userId
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setUsers(response.data);
            }else{
                setError(response.data.message);
            }
            setLoading(false);
        })
        .catch(function (error:any) {
            console.log("error",error);
        });
    },[userId]);

    if (error) {
        setMessage(error);
        setSnackbar(true);
        return (
            <div className="container-main">
                <Empty />
            </div>
        );
    }
    let detailsObject: any = {};
    let activeTest: any[] = [];

    if (!loading && users.users.length > 0) {
        const user = users.users[0];
        activeTest = user.purchases.map(
            (purchase: any) =>
                purchase.responses_aggregate === 0 &&
                purchase.test.name
        );

        console.log(user);

        detailsObject = {
            Name: user.name,
            Email: user.email ?? "NA",
            Phone: user.phoneNumber ?? "NA",
            // Age: user.DOB ? parseIsoToDate(user.DOB) : "NA",
            Gender: user.sex ?? "NA",
            "How did you know about Eval?": user.reference ?? "NA",
            "Signed up on": parseIsoToDate(user.created_at),
        };

        detailsObject = Object.entries(detailsObject);
    } else if (!loading && users.users.length === 0) {
        setMessage(" Either user does not exist or is deleted by the admin");
        setSnackbar(true);
        return (
            <div className="container-main">
                <div className="user__detail-box">
                    <Empty />
                </div>
            </div>
        );
    }
    return (
        <div className="container-main">
            <div className="user__detail-box">
                {loading ? (
                    <div className="h-100">
                        <div className="spinner-container">
                            <CircularProgress size={40} thickness={4} />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="user__detail-title__container">
                            <p className="user__detail-title"> User Id</p>
                            <p className="user__detail-subtitle">
                                {" "}
                                {users.users[0].id}
                            </p>
                        </div>

                        <div className="user__detail-main">
                            <div>
                                {detailsObject.map(
                                    (element: any[], index: number) => {
                                        return (
                                            <ReportDetailElement
                                                key={index}
                                                label={element[0]}
                                                value={element[1]}
                                            />
                                        );
                                    }
                                )}
                            </div>

                            <div style={{ textAlign: "left" }}>
                                <p className="user__active">Active test</p>

                                {activeTest.map((element, idx: number) => (
                                    <p className="user__test" key={idx}>
                                        {element}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default UserDetail;
