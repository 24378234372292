import React, { useEffect,useState, useContext } from "react";
import "./Report.scss";
import SearchBox from "../../UI/SearchBox/SearchBox";
import { getReports, getReportsCount } from "./ReportQuery";
import { useQuery } from "@apollo/client";
import SnackbarContext from "../../../Context/SnackbarContext";
import { CircularProgress } from "@material-ui/core";
import Empty from "../../UI/Empty/Empty";
import { parseIsoToDate } from "../../../Utils/parseIsoToDate";
import right from "../../../Assets/Images/rightArrow.svg";
import left from "../../../Assets/Images/leftArrow.svg";
import { useHistory } from "react-router";
import APP from '../../../services/app-service'
import { off } from "process";
const GLOBAL = require('../../../services/global-constant');
const axios = require('axios').default;

const Report: React.FC = () => {
    const [searchString, setSearch] = useState<string>("%%");
    const [purchaseId, setPurchaseId] = useState<number | null>(null);

    const { setSnackbar, setMessage } = useContext(SnackbarContext);
    const [limit] = useState(8);
    const [offset, setOffset] = useState(0);

    const [reports, setReports] = useState<any>(null);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [reportCount, setReportCount] = useState(0);

    useEffect(() => {

        console.log("hello");
        setLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getAdminRport,{
            limit:limit,
            offset:offset
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setReports(response.data);
                setReportCount(response.data.count);
            }else{
                setError(response.data.message);
            }
            setLoading(false);

        })
        .catch(function (error:any) {
            console.log("error",error);
        });
        console.log(limit,offset,reportCount)
    },[offset]);

    //--- old query---
    // const { data: reports, error, loading } = useQuery(getReports, {
    //     variables: { searchString, purchaseId, limit, offset },
    // });
    // const {
    //     data: reportCount,
    //     error: errorCount,
    //     loading: countLoading,
    // } = useQuery(getReportsCount);

    if (error ) {
        setMessage(
            error
                ? error
                : "Some error occurred"
        );
        setSnackbar(true);
        return (
            <div className="container-main">
                <Empty />
            </div>
        );
    }

    console.log(limit,offset,reportCount)
    return (
        <div className="container-main">
            <div className="report__search-container">
                <SearchBox
                    placeholder="Search with name, number, email"
                    onChangeSearch={(value) => {
                        setPurchaseId(null);
                        setSearch(`%${value}%`);
                    }}
                />
                {/* <SearchBox
                    type="number"
                    placeholder="Search with purchase id"
                    onChangeSearch={(value) => {
                        setSearch("%%");
                        setPurchaseId(parseInt(value));
                    }}
                /> */}
            </div>

            <div className="report__box">
                <ReportTitle />
                <div className="report_main">
                    {loading || reports==null ? (
                        <div className="spinner-container">
                            <CircularProgress size={40} thickness={4} />
                        </div>
                    ) : reports.responses.length > 0 ? (
                        reports.responses.map((element: any) => {
                            return (
                                <ReportItem
                                    element={element}
                                    key={element.id}
                                />
                            );
                        })
                    ) : (
                        <Empty />
                    )}
                </div>
                {!loading || reports!=null? (
                    <Pagination
                        limit={limit}
                        offset={offset}
                        count={reportCount}
                        changeOffset={(value) => setOffset(value)}
                    />
                ):""}
            </div>
        </div>
    );
};

export default Report;

const ReportTitle: React.FC = () => {
    return (
        <div className="report__table">
            <p className="report__title">Purchase ID</p>
            <p className="report__title">Date</p>
            <p className="report__title">Test</p>
            <p className="report__title">Name</p>
            <p className="report__title">Code</p>
            <p className="report__title">Email</p>
        </div>
    );
};

export interface ReportItemProps {
    element: any;
}

const ReportItem: React.FC<ReportItemProps> = (props: ReportItemProps) => {
    const { element } = props;
    const history = useHistory();

    return (
        <div className="report__table card-animation">
            <p className="report__item">{element.purchase.id}</p>
            <p className="report__item">{parseIsoToDate(element.createdAt)}</p>
            <p className="report__item">{element.purchase.test.name}</p>
            <p className="report__item">{element.purchase.user.name}</p>
            {element.purchase.promo_code?(<p className="report__item">{element.purchase.promo_code.promoCode}</p>): null}
            {element.purchase.merchant_code?(<p className="report__item">{element.purchase.merchant_code.merchant.name}</p>): null}
            {(element.purchase.merchant_code||element.purchase.promo_code)? null : <p className="report__item"></p>}
            <p className="report__item">{element.purchase.user.email}</p>

            <div className="report__actions">
                <button
                    className="btn-list"
                    type="button"
                    onClick={() => {
                        history.push(`/dashboard/reports/${element.id}`);
                    }}
                >
                    view
                </button>
            </div>
        </div>
    );
};

interface FooterProps {
    limit: number;
    offset: number;
    count: number;
    changeOffset: (value: any) => any;
}

export const Pagination: React.FC<FooterProps> = (props: FooterProps) => {
    const { limit, offset, changeOffset, count } = props;

    const leftIconStyle = {
        opacity: offset ? 1 : 0.3,
        cursor: offset ? "pointer" : "not-allowed",
    };

    const rightIconStyle = {
        opacity: count > offset + limit ? 1 : 0.3,
        cursor: count > offset + limit ? "pointer" : "not-allowed",
    };

    return (
        <div className="report__footer">
            <p className="pagination__title">
                {offset + 1} &nbsp; -{" "}
                {offset + limit < count ? offset + limit : count}
                &nbsp; of &nbsp;
                {count} &nbsp;
            </p>
            <img
                src={left}
                style={leftIconStyle}
                alt="Last Page"
                className="pagination__icon"
                onClick={() => {
                    changeOffset((oldOffset: number) =>
                        offset > 0 ? oldOffset - limit : oldOffset
                    );
                }}
            />
            <img
                src={right}
                style={rightIconStyle}
                alt="Next Page"
                onClick={() => {
                    changeOffset((oldOffset: number) =>
                        count > oldOffset + limit
                            ? oldOffset + limit
                            : oldOffset
                    );
                }}
                className="pagination__icon"
            />
        </div>
    );
};
