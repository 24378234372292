import React, { useContext, useEffect, useState } from "react";
import "../Test.scss";
import ReactModal from "react-modal";
import "./TestQuestion.scss";
import Input from "../../../UI/Input/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContext from "../../../../Context/SnackbarContext";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";
import close from "../../../../Assets/Images/cross.svg";
import { useMutation, useQuery } from "@apollo/client";
import { GetTestDetail, InsertQuestion, UpdateQuestion } from "../TestQuery";
import { Error } from "../../Promocode/Promocode";
import Search from "../../../UI/Search/Search";
import { useParams } from "react-router";
import APP from '../../../../services/app-service'
const GLOBAL = require('../../../../services/global-constant');
const axios = require('axios').default;

interface PromoEditProps {
    isOpen: boolean;
    editData: any;
    setEditData: (element: any | null) => void;
    setModal: (value: boolean) => void;
    refetch: () => void;
    countRefetch: () => void;
}

const TestQuestion: React.FC<PromoEditProps> = ({
    isOpen,
    editData,
    setEditData,
    setModal,
    refetch,
    countRefetch,
}) => {
    const [question, setQuestion] = useState<Map<number, string>>(
        new Map<number, string>()
    );

    const [priority, setPriority] = useState<number>(1);
    const [category, setCategory] = useState<{
        id: number;
        name: string;
    } | null>(null);

    const [insertQuestion] = useMutation(InsertQuestion);
    const [updateQuestion] = useMutation(UpdateQuestion);

    const [mutationLoading, setMutationLoading] = useState(false);
    const { setSnackbar, setMessage } = useContext(SnackbarContext);
    const { id: testId } = useParams<{ id: string }>();


    const clearState = () => {
        setQuestion(new Map<number, string>());
        setPriority(1);
        setCategory(null);
        setEditData(null);
    };

    const [languages, setData] = useState<any>(null);
    const [languageError, setError] = useState();
    const [languageLoading, setLoading] = useState(true);

    // const {
    //     data: languages,
    //     error: languageError,
    //     loading: languageLoading,
    // } = useQuery(GetTestDetail, {
    //     variables: {
    //         testId: +testId,
    //     },
    // });

    useEffect(() => {
        console.log("sdsd");
        setLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getAdminTestEditDetail,{
            testId:testId
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setData(response.data);
            }else{
                setError(response.data.message);
            }
            setLoading(false);
        })
        .catch(function (error:any) {
            console.log("error",error);
        });
    }, [testId]);

    useEffect(() => {
        if (!languageLoading && languages.languages.length > 0) {
            let languageMap = new Map();

            if (!editData) {
                languages.languages.forEach((element: any) => {
                    languageMap.set(element.id, "");
                });
                setQuestion(languageMap);
            } else {
                setPriority(editData.priority);
                setCategory({
                    id: editData.categoryId,
                    name: editData.category.name,
                });
                editData.question_languages.forEach((element: any) => {
                    languageMap.set(element.languageId, element.question);
                });
                setQuestion(languageMap);
            }
        }
    }, [setQuestion, languages, languageLoading, editData]);

    const callbacks = () => {
        setSnackbar(true);
        refetch();
        countRefetch();
        clearState();
        setModal(false);
        setMutationLoading(false);
    };

    const onSave = () => {
        let questionLanguages: any[] = [];

        question.forEach((question, id) => {
            questionLanguages.push({
                languageId: id,
                question,
            });
        });

                console.log("ONNSAVE",testId,
                priority,
                category ,
                "questionLanguages-",questionLanguages)

        
        setMutationLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.addTest,{         
            testId:testId,
            priority:priority,
            categoryId:category && category.id,
            questionLanguages:questionLanguages
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setMessage("Questions Added Successfully");
                callbacks();
                setMutationLoading(false);
            }else{
                setError(response.data.message);
                     setMessage("Some Unknown Error Occurred");
                     setMutationLoading(false);
                     return setSnackbar(true);
            }
        })
        .catch(function (error:any) {
            console.log("error",error);
        });

        //setMutationLoading(true);
        // insertQuestion({
        //     variables: {
        //         testId,
        //         priority,
        //         categoryId: category && category.id,
        //         questionLanguages,
        //     },
        // })
        //     .then(({ data: { insert_questions: questions } }: any) => {
        //         if (questions.affected_rows > 0) {
        //             setMessage("Questions Added Successfully");
        //             callbacks();
        //         } else {
        //             setMessage("Some Unknown Error Occurred");
        //             setMutationLoading(false);
        //             return setSnackbar(true);
        //         }
        //     })
        //     .catch((error) => {
        //         setMessage(error.message);
        //         setSnackbar(true);
        //         setMutationLoading(false);
        //     });


        

    };

    const onEdit = () => {
        let questionLanguages: any[] = [];
        question.forEach((question, id) => {
            questionLanguages.push({
                languageId: id,
                question,
                questionId: editData.id,
            });
        });

        setMutationLoading(true);


        
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.updateTestQuestion,{ 
            questionId: editData.id,
            priority:priority,
            categoryId:category && category.id,
            questionLanguages:questionLanguages
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setMessage("Questions Update Successfully");
                callbacks();
            }else{
                setMessage("Some Unknown Error Occurred");
                setMutationLoading(false);
                return setSnackbar(true);
            }
        })
        .catch(function (error:any) {
            console.log("error",error);
        });


        // updateQuestion({
        //     variables: {
        //         questionId: editData.id,
        //         priority,
        //         categoryId: category && category.id,
        //         questionLanguages,
        //     },
        // })
        //     .then(
        //         ({
        //             data: {
        //                 update_questions: questions,
        //                 delete_question_languages: deleteQuestions,
        //                 insert_question_languages: insertQuestions,
        //             },
        //         }: any) => {
        //             if (
        //                 questions.affected_rows > 0 &&
        //                 deleteQuestions.affected_rows > 0 &&
        //                 insertQuestions.affected_rows > 0
        //             ) {
        //                 setMessage("Questions Update Successfully");
        //                 callbacks();
        //             } else {
        //                 setMessage("Some Unknown Error Occurred");
        //                 setMutationLoading(false);
        //                 return setSnackbar(true);
        //             }
        //         }
        //     )
        //     .catch((error) => {
        //         setMessage(error.message);
        //         setSnackbar(true);
        //         setMutationLoading(false);
        //     });
    };

    if (languageError) {
        return <Error error={languageError} />;
    }

    return (
        <ReactModal
            className="question__modal Modal__main"
            onRequestClose={() => {
                clearState();
                setModal(false);
            }}
            overlayClassName="Overlay"
            isOpen={isOpen}
        >
            <div className="close">
                <img
                    src={close}
                    alt="Close"
                    className="close__img close__img-user"
                    onClick={() => {
                        clearState();
                        setModal(false);
                    }}
                />
            </div>

            {languageLoading ? (
                <div className="h-100">
                    <div className="spinner-container">
                        <CircularProgress size={40} thickness={4} />
                    </div>
                </div>
            ) : (
                <div className="question__modal__content">
                    <p className="question__modal__header">
                        {editData ? "Edit Question" : "Add Question"}
                    </p>
                    <div className="margin-auto w-75">
                        {languages.languages.map((language: any) => {
                            return (
                                <Input
                                    key={language.id}
                                    placeholder={language.name}
                                    label
                                    type={"text"}
                                    disabled={false}
                                    value={`${question.get(language.id) || ""}`}
                                    onChange={(event: string) => {
                                        let tempMap = new Map(question);
                                        tempMap.set(language.id, event);
                                        setQuestion(tempMap);
                                    }}
                                />
                            );
                        })}

                        <div className="category__container">
                            <Search
                                label={"Category"}
                                value={category ? category.name : ""}
                                options={languages.categories}
                                onChangeSelect={(value) => {
                                    setCategory(value);
                                }}
                            />
                            <Input
                                placeholder={"Priority"}
                                label={true}
                                type={"number"}
                                disabled={false}
                                value={priority.toString()}
                                onChange={(event: string) => {
                                    setPriority(parseInt(event));
                                }}
                            />
                        </div>
                    </div>

                    {!mutationLoading ? (
                        <div
                            className="w-40 margin-auto"
                            style={{ marginTop: "2em" }}
                        >
                            <PrimaryButton
                                label="Save"
                                disabled={
                                    Array.from(question.values()).includes(
                                        ""
                                    ) ||
                                    !category ||
                                    !priority
                                }
                                loading={mutationLoading}
                                active
                                onClick={() => {
                                    editData ? onEdit() : onSave();
                                }}
                            />
                        </div>
                    ) : (
                        <div
                            className="spinner-container"
                            style={{ marginTop: "3em" }}
                        >
                            <CircularProgress size={40} thickness={4} />
                        </div>
                    )}
                </div>
            )}
        </ReactModal>
    );
};

export default TestQuestion;
