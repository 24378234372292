module.exports = {
  //-------LOCAL--------
  //BASE_URL: 'http://127.0.0.1:8000/api/',
  //RAZOR_KEY:'rzp_test_ArGg1vnszTHsIx',
  //-------LOCAL--------
  //----UAT-----
  // BASE_URL: 'http://eval-api.vvsservices.in/api/',
  // RAZOR_KEY:'rzp_test_ArGg1vnszTHsIx',
  //----UAT-----
  //----PROD-----
  BASE_URL: 'https://api.evaltest.com/api/',
  //BASE_URL: 'http://64.227.155.85/api/',
  RAZOR_KEY:'rzp_test_ArGg1vnszTHsIx',
  //RAZOR_KEY:'rzp_live_neX384jD9FybkK',//prod
  //----PROD-----
  ENDPOINTS: {
    register: 'register',
    login: 'login',
    getUserDetails:'get-user-details',
    getUserPurchases:'get-user-purchases',
    logout:'logout',
    getVoucherCheckData:'get-voucher-check-data',
    createMerchantPurchase:'purchase/create-merchant-purchase',
    createPurchase:'purchase/create-purchase',
    deletePurchase:'purchase/delete-purchase',
    verifyPurchase:'purchase/verify-purchase',
    getTestPurchasedCheck:'get-test-purchased-check',
    getTestCategories:'get-test-categories',
    getTest:'get-test',
    setTest:'set-test',
    getViewReport:'get-view-report',
    //-----Admin-----
    getAdminRport:'admin/report',
    getAdminRportDetails:'admin/report-details',
    getAdminAllTest:'admin/get-all-test',
    getAdminTestDetail:'admin/get-test-detail',
    getAdminTestEditDetail:'admin/get-test-edit-detail',
    addTest:'admin/add-test-question',
    updateTestQuestion:'admin/update-test-question',
    updateTestAmount:'admin/update-test-amount',
    getUsers:'admin/get-users',
    getUserById:'admin/get-user-by-id',
    getPromoCodes:'admin/get-promo-codes',
    addPromoCodes:'admin/add-promo-codes',
    updatePromoCodes:'admin/update-promo-codes',
    togglePromoCodeIsDeletedMutation:'admin/toggle-promo-code-is-deleted',
    resetPromoCodeCount:'admin/reset-promo-code-count',
    getMerchantCodes:'admin/get-merchant-codes',
    deleteMerchant:'admin/delete-merchant',
    getMerchantCodeDetails:'admin/get-merchant-code-details',
    createMerchant:'admin/add-merchant',
    addMerchantCodes:'admin/add-merchant-codes',
    getContactUs:'admin/get-contact-us',
    deleteContactUs:'admin/delete-contact-us'
  },
  ASSETS:{
    noProfilePic:"/app-assets/others/default_user_profile_pic.png"
  },
  STORAGE:{
    ls_token:"admin_ld_token"
  }
};