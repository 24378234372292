import React from "react";
import { Redirect, Route, Switch } from "react-router";
import Nav from "./Nav/Nav";
import "./Dashboard.scss";
import Report from "./Report/Report";
import ReportDetail from "./Report/ReportDetail/ReportDetail";
import User from "./User/User";
import UserDetail from "./User/UserDetail/UserDetail";
import Promocode from "./Promocode/Promocode";
import Test from "./Test/Test";
import TestDetail from "./Test/TestDetail/TestDetail";
import Merchant from "./Merchants/Merchant";
import ContactUs from "./ContactUs/ContactUs";

const Dashboard: React.FC = () => {
   

    return (
        <div className="dashboard">
            <div className="dashboard__nav ">
                <Nav />
            </div>
            <div className="dashboard__main box-shadow-sm ">
                <Switch>
                    <Route exact={true} path="/dashboard/reports">
                        <Report />
                    </Route>
                    <Route exact={true} path="/dashboard/reports/:id">
                        <ReportDetail />
                    </Route>

                    <Route exact={true} path="/dashboard/tests">
                        <Test />
                    </Route>

                    <Route exact={true} path="/dashboard/tests/:id">
                        <TestDetail />
                    </Route>
                    <Route exact={true} path="/dashboard/users">
                        <User />
                    </Route>

                    <Route exact={true} path="/dashboard/users/:id">
                        <UserDetail />
                    </Route>
                    <Route exact={true} path="/dashboard/promocodes">
                        <Promocode />
                    </Route>

                    <Route exact={true} path="/dashboard/merchants">
                        <Merchant />
                    </Route>
                    <Route exact={true} path="/dashboard/contact-us">
                        <ContactUs />
                    </Route>
                    <Redirect to="/dashboard/reports" />
                </Switch>
            </div>
        </div>
    );
};

export default Dashboard;
