import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext,useEffect,useState } from "react";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import SnackbarContext from "../../../../Context/SnackbarContext";
import { parseIsoToDate } from "../../../../Utils/parseIsoToDate";
import Empty from "../../../UI/Empty/Empty";
import { getReportByReportId } from "../ReportQuery";
import APP from '../../../../services/app-service'
const GLOBAL = require('../../../../services/global-constant');
const axios = require('axios').default;

const ReportDetail: React.FC = () => {
    const { id: reportId } = useParams<{ id: string | undefined }>();
    const { setSnackbar, setMessage } = useContext(SnackbarContext);
    const history = useHistory();

    // const { data: report, error, loading } = useQuery(getReportByReportId, {
    //     variables: { reportId },
    // });

    const [report, setReport] = useState<any>(null);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    console.log("1->",reportId);
    useEffect(() => {

        console.log(reportId);
        setLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getAdminRportDetails,{
            reportId:reportId
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setReport(response.data);
            }else{
                setError(response.data.message);
            }
            setLoading(false);

        })
        .catch(function (error:any) {
            console.log("error",error);
        });
    },[reportId]);

    if (error) {
        setMessage(error ? error : "Some error occurred");
        setSnackbar(true);
        return (
            <div className="container-main">
                <Empty />
            </div>
        );
    }
    let detailsObject: any = {};
    let questionObject: any = {};
    let scores: any = [];

    const convertToDuration = (isoString: string): string => {
        const date = new Date(isoString);
        return `${date.getUTCHours()}:
            ${date.getUTCMinutes()}:
            ${date.getUTCSeconds()}`;
    };

    if (!loading && report!=null && report.responses.length > 0) {
        const response = report.responses[0];
        const onboarding = response.onboardings[0]?response.onboardings[0]:{};

        scores = [...response.scores];
        detailsObject = {
            Name: response.purchase.user.name,
            Test: response.purchase.test.name,
            "Duration (HH:MM:SS)": convertToDuration(response.duration),
            Email: response.purchase.user.email,
            Phone: response.purchase.user.phoneNumber,
            Coupon: response.purchase.promo_code
                ? response.purchase.promo_code.promoCode
                : "null",
            "Merchant Coupon": response.purchase.merchant_code
                ? response.purchase.merchant_code.code
                : "null",
            "Purchase Id": response.purchase.id,
            "User Id": response.purchase.user.id,
            Date: parseIsoToDate(response.createdAt),
        };

        questionObject = {
            "Where are you from ?": onboarding.location,
            "Current year of study ?": onboarding.year,
            "What profession would you like to join in the future?":
                onboarding.profession,
            "Are you considering studying abroad? If yes, where would you like to study? Please mention the level (Bachelor’s, Master’s, Diploma, PhD)":
                onboarding.level,
        };

        detailsObject = Object.entries(detailsObject);

        questionObject = Object.entries(questionObject);
    } else if (!loading && report!=null && report.responses.length === 0) {
        setMessage("Report either does not exist or is deleted by the admin");
        setSnackbar(true);
        return (
            <div className="container-main">
                <div className="report__detail-box">
                    <Empty />
                </div>
            </div>
        );
    }
    return (
        <div className="container-main">
            <div className="report__detail-box">
                {!loading && report!=null ? (
                    <>
                    <div className="report__detail-title__container">
                        <p className="report__detail-title"> Purchase Id</p>
                        <p className="report__detail-subtitle">
                            {" "}
                            {report.responses[0].purchase.id}
                        </p>
                    </div>

                    <div className="report__detail-main">
                        <div className="">
                            {detailsObject.map((element: any[]) => {
                                return (
                                    <ReportDetailElement
                                        label={element[0]}
                                        value={element[1]}
                                    />
                                );
                            })}
                        </div>

                        <div>
                            {questionObject.map((element: any[]) => {
                                return (
                                    <ReportQuestionElement
                                        label={element[0]}
                                        value={element[1]}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <p
                        className="report__detail-title"
                        style={{ textAlign: "left" }}
                    >
                        Score
                    </p>

                    <div className="report__score">
                        {scores.map((element: any) => {
                            return (
                                <ReportDetailElement
                                    label={element.category.name}
                                    value={element.score}
                                />
                            );
                        })}
                    </div>

                    <div className="report__download">
                        <div className="report__download-button" onClick={() => {
                            history.push("/view-report-pdf/" + reportId + "/1")
                        }}>Download in English</div>
                        <div className="report__download-button" onClick={() => {
                            history.push("/view-report-pdf/" + reportId + "/3")
                        }}>Download in Hindi</div>
                        <div className="report__download-button" onClick={() => {
                            history.push("/view-report-pdf/" + reportId + "/4")
                        }}>Download in Marathi</div>
                        <div className="report__download-button" onClick={() => {
                            history.push("/view-report-pdf/" + reportId + "/5")
                        }}>Download in Gujrathi</div>
                    </div>
                </>
                ) : (

                    <div className="h-100">
                        <div className="spinner-container">
                            <CircularProgress size={40} thickness={4} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReportDetail;

interface DetailElementProps {
    label: string;
    value: string;
}

export const ReportDetailElement: React.FC<DetailElementProps> = (
    props: DetailElementProps
) => {
    const { label, value } = props;
    return (
        <div className="report__element-container">
            <p className="report__label"> {label}</p>
            {label === "User Id" ? (
                <NavLink
                    to={`/dashboard/users/${value}`}
                    activeClassName="nav__item-selected"
                >
                    <p className="report__link"> {value} </p>
                </NavLink>
            ) : (
                <p className="report__value"> {value}</p>
            )}
        </div>
    );
};

export const ReportQuestionElement: React.FC<DetailElementProps> = (
    props: DetailElementProps
) => {
    const { label, value } = props;
    return (
        <div
            className="report__question-container w-90"
            style={{ width: "90%" }}
        >
            <p className="report__question"> {label}</p>
            <p className="report__value"> {value}</p>
        </div>
    );
};
