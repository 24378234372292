import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React,{useState,useEffect} from "react";
import "./Test.scss";
import { GetTest } from "./TestQuery";
import { Error } from "../Promocode/Promocode";
import Empty from "../../UI/Empty/Empty";
import { useHistory } from "react-router";
import APP from '../../../services/app-service'
const GLOBAL = require('../../../services/global-constant');
const axios = require('axios').default;

const Test: React.FC = () => {
    const [tests, setTests] = useState<any>(null);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    //const { data: tests, error, loading } = useQuery(GetTest);

    useEffect(() => {
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getAdminAllTest,{
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setTests(response.data);
            }else{
                setError(response.data.message);
            }
            setLoading(false);

        })
        .catch(function (error:any) {
            console.log("error",error);
        });
    },[]);

    if (error) {
        return <Error error={error} />;
    }
    return (
        <div className="container-main">
            <div className="test__box">
                <div className="test__main">
                    <p className={"test__title"}>Tests</p>

                    <TestTitle />
                    {loading ? (
                        <div className="spinner-container">
                            <CircularProgress size={40} thickness={4} />
                        </div>
                    ) : tests.tests.length > 0 ? (
                        tests.tests.map((element: any) => {
                            return (
                                <TestItem element={element} key={element.id} />
                            );
                        })
                    ) : (
                        <Empty />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Test;

const TestTitle: React.FC = () => {
    return (
        <div className="test__table">
            <p className="promo__title">ID</p>
            <p className="promo__title">Test</p>
        </div>
    );
};

interface PromoItemProps {
    element: any;
}

const TestItem: React.FC<PromoItemProps> = ({ element }: PromoItemProps) => {
    const history = useHistory();
    return (
        <div className="test__table">
            <p className="promo__item">{element.id}</p>
            <p className="promo__item">{element.name}</p>
            <div className="test__actions">
                <button
                    className="btn-list"
                    onClick={() => {
                        history.push(`/dashboard/tests/${element.id}`);
                    }}
                >
                    view
                </button>
            </div>
        </div>
    );
};
