import React, { useState, useEffect } from "react";
import "./App.css";
import { Switch, Route, Redirect } from "react-router";
import Login from "./Components/Login/Login";
import SnackbarContext from "./Context/SnackbarContext";
import Dashboard from "./Components/Dashboard/Dashboard";
import Snackbar from "@material-ui/core/Snackbar";
import ReportPdf from './Components/Dashboard/Report/ReportDetail/ReportDownload/ReportPdf2';
import firebase from "firebase";
import APP from './services/app-service'
const GLOBAL = require('./services/global-constant');

const App: React.FC = () => {
    const [user, setUser] = useState<any>("true");

    const [snackbar, setSnackbar] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        // const sub = firebase.auth().onAuthStateChanged((user) => {
        //     setUser(user);
        // });
        // return () => {
        //     sub();
        // };

        setUser(APP.getRouteComponentData(GLOBAL.STORAGE.ls_token)?true:false);
    }, []);

    return (
        <SnackbarContext.Provider
            value={{
                snackbar,
                setSnackbar,
                message,
                setMessage,
            }}
        >
            <div className="App">
                <Switch>
                    <Route
                        path="/dashboard"
                        render={() => {
                            return user ? (
                                <Dashboard />
                            ) : (
                                <Redirect to="/login" />
                            );
                        }}
                    />
                    <Route
                        path="/view-report-pdf/:responseId/:languageId"
                        render={() => {
                            return user ? (
                                <ReportPdf/>
                            ) : (
                                <Redirect to="/login" />
                            );
                        }}
                    />

                    <Route
                        path="/login"
                        render={() => {
                            return user ? (
                                <Redirect to="/dashboard" />
                            ) : (
                                <Login />
                            );
                        }}
                    />                                            
                    <Redirect to="/login" />
                </Switch>

                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={snackbar}
                    autoHideDuration={5000}
                    message={message}
                    onClose={() => {
                        setSnackbar(false);
                    }}
                />
            </div>
        </SnackbarContext.Provider>
    );
};

export default App;
