import React from "react";
import ReactModal from "react-modal";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import close from "../../../Assets/Images/cross.svg";
import "./DeleteModal.scss";

interface DeleteModalProps {
    modal: boolean;
    loading: boolean;
    setModal: (value: boolean) => void;
    onClickDelete: () => void;
    text?: string;
    buttonLabel?: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
    loading,
    setModal,
    modal,
    onClickDelete,
    text,
    buttonLabel,
}) => {
    return (
        <ReactModal
            className="delete Modal__main"
            overlayClassName="Overlay"
            isOpen={modal}
        >
            <div className="close">
                <img
                    src={close}
                    alt="Close"
                    className="close__img close__img-user"
                    onClick={() => {
                        setModal(false);
                    }}
                />
            </div>
            <div className="delete__content">
                <p className="delete__header">
                    {text ? text : "Are you sure you want to delete ?"}
                </p>
                <div className="w-40 margin-auto" style={{ marginTop: "2em" }}>
                    <PrimaryButton
                        label={buttonLabel ? buttonLabel : "YES"}
                        disabled={false}
                        loading={loading}
                        active
                        onClick={() => {
                            onClickDelete();
                        }}
                    />
                </div>
            </div>
        </ReactModal>
    );
};

export default DeleteModal;
