import React, { useState, useContext,useEffect } from "react";
import "./User.scss";
import SearchBox from "../../UI/SearchBox/SearchBox";
import { getUsers, getUsersCount } from "./UserQuery";
import { useQuery } from "@apollo/client";
import SnackbarContext from "../../../Context/SnackbarContext";
import { CircularProgress } from "@material-ui/core";
import Empty from "../../UI/Empty/Empty";
import { useHistory } from "react-router";
import { Pagination } from "../Report/Report";
import APP from '../../../services/app-service'
const GLOBAL = require('../../../services/global-constant');
const axios = require('axios').default;

const User = () => {
    const [searchString, setSearch] = useState<string | null>("%%");
    const [userId, setUserId] = useState<number | null>(0);

    const { setSnackbar, setMessage } = useContext(SnackbarContext);
    const [limit] = useState(7);
    const [offset, setOffset] = useState(0);

    const [evalListActive, setEvalListActive] = useState<boolean>(true);

    const [users, setUsers] = useState<any>(null);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);

    const [usersCount, setUsersCount] = useState<any>(null);
    const [errorCount, setErrorCount] = useState();
    const [countLoading, setCountLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setCountLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getUsers,{
            limit:limit,
            offset:offset,
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setUsers(response.data);
                setUsersCount(response.data.usersCount);
            }else{
                setError(response.data.message);
                //setErrorCount('1');
            }
            setLoading(false);
            setCountLoading(false);
        })
        .catch(function (error:any) {
            console.log("error",error);
        });
    },[offset]);

    // const {
    //     data: users,
    //     error,
    //     loading,
    // } = useQuery(getUsers, {
    //     variables: {
    //         searchString: searchString,
    //         userId,
    //         limit,
    //         offset,
    //         showVcura: evalListActive,
    //     },
    // });
    // const {
    //     data: usersCount,
    //     error: errorCount,
    //     loading: countLoading,
    // } = useQuery(getUsersCount, {
    //     variables: {
    //         searchString: searchString,
    //         userId,
    //         showVcura: evalListActive,
    //     },
    // });

    if (error || errorCount) {
        setMessage(
            //error
                // ? error.message
                // : errorCount
                //? errorCount.message
                //:
                 //"Some error occurred"
                 error
        );
        setSnackbar(true);
        return (
            <div className="container-main">
                <Empty />
            </div>
        );
    }

    return (
        <div className="container-main">
            <div className="report__search-container">
                <SearchBox
                    placeholder={
                        evalListActive
                            ? "Search with name, number and email"
                            : "Search with name"
                    }
                    onChangeSearch={(value) => {
                        setUserId(null);
                        setSearch(`%${value}%`);
                    }}
                />
                {/* <SearchBox
                    type="number"
                    placeholder="Search with user id"
                    onChangeSearch={(value) => {
                        setSearch("%%");
                        setUserId(parseInt(value));
                    }}
                /> */}
            </div>
            <div className="user__box">
                <div className="user__main">
                    <div className="promo__header">
                        <p>Users</p>
                        {/* <button
                            type="button"
                            style={{ width: "max-content" }}
                            onClick={() => {
                                // if (evalListActive) {
                                //     setSearch(null);
                                // } else {
                                //     setSearch("");
                                // }
                                setEvalListActive((value) => !value);
                            }}
                        >
                            {!evalListActive
                                ? "Show Eval Users"
                                : "Show Vcura Users"}
                        </button> */}
                    </div>

                    <UserTitle />

                    {loading || countLoading ? (
                        <div className="spinner-container">
                            <CircularProgress size={40} thickness={4} />
                        </div>
                    ) : users.users.length > 0 ? (
                        users.users.map((element: any) => {
                            return (
                                <ReportItem
                                    element={element}
                                    key={element.id}
                                />
                            );
                        })
                    ) : (
                        <Empty />
                    )}
                </div>
                {!loading && !countLoading && (
                    <Pagination
                        limit={limit}
                        offset={offset}
                        count={usersCount}
                        changeOffset={(value) => setOffset(value)}
                    />
                )}
            </div>
        </div>
    );
};

const UserTitle: React.FC = () => {
    return (
        <div className="user__table">
            <p className="report__title">User ID</p>
            <p className="report__title">User Name</p>
            <p className="report__title">Email</p>
            <p className="report__title">Phone</p>
            <p className="report__title">Active Test</p>
        </div>
    );
};

export interface ReportItemProps {
    element: any;
}

const ReportItem: React.FC<ReportItemProps> = (props: ReportItemProps) => {
    const { element } = props;
    const history = useHistory();

    const getCount = (): number => {
        let activeTest: number[] = [];
        element.purchases.forEach((purchase: any) => {
            if (purchase.responses_aggregate === 0) {
                activeTest.push(purchase.responses_aggregate);
            }
        });
        return activeTest.length;
    };

    return (
        <div className="user__table card-animation">
            <p className="report__item">{element.id}</p>
            <p className="report__item">{element.name}</p>
            <p className="report__item">{element.email ?? "NA"}</p>
            <p className="report__item">{element.phoneNumber ?? "NA"}</p>
            <p className="report__item">{getCount()}</p>

            <div className="report__actions">
                <button
                    className="btn-list"
                    type="button"
                    onClick={() => {
                        history.push(`/dashboard/users/${element.id}`);
                    }}
                >
                    view
                </button>
            </div>
        </div>
    );
};

export default User;
