import { gql } from "@apollo/client";

export const GetMerchants = gql`
	query GetMerchantsCode($searchString: String!, $limit: Int!, $offset: Int!) {
		merchants(where: {isDeleted: {_eq: false}, name: {_ilike: $searchString}}, order_by: {createdAt: desc}, limit: $limit, offset: $offset) {
			id
			name
			email
			promo_code {
				associate
			}
			testId
			test {
				name
			}
			merchant_codes_aggregate {
				aggregate {
					count
				}
			}
			merchant_codes {
				id
				code 
			}
		}
	}
`;



export const DeleteMerchant = gql`
	mutation DeleteMerchant($merchantId: Int!) {
		delete_merchants(where: {id: {_eq: $merchantId}}) {
			affected_rows
		}
	}
`;


export const GetMerchantCodeDetails = gql`
	query GetMerchantCodeDetails {
		tests(where: {isDeleted: {_eq: false}}, order_by: {id: asc}) {
			id
			name
		}
		promo_codes(where: {isDeleted: {_eq: false}}, order_by: {id: asc}) {
			id
			promoCode
		}
	}
`;


export const CreateMerchantCodes = gql`
	mutation CreateMerchantCodes($count: Int!, $email: String!, $name: String!, $testId: Int!, $promoCodeId: Int) {
		createMerchantCodes(input: {count: $count, email: $email, name:$name, testId: $testId, promoCodeId: $promoCodeId}) {
			affectedRows
			code
			message
		}
	}
`;



export const GetMerchantCount = gql`
	query GetMerchantCodes($searchString: String!) {
		merchants_aggregate(where: {isDeleted: {_eq: false}, name: {_ilike: $searchString}}) {
			aggregate {
			count
			}
		}
	}
`;