import { gql } from "@apollo/client";

export const GetContactUs = gql`
	query GetContactUs($searchString: String!, $limit: Int!, $offset: Int!) {
		contact_us(order_by: {id: desc}, limit: $limit, offset: $offset, where: {email: {_ilike: $searchString}}) {
			id
			name
			phoneNumber
			query
			reference
			subject
			email
		}
	}
`;


export const GetContactUsCount = gql`
	query GetContactUsCount($searchString: String!) {
		contact_us_aggregate(where: {email: {_ilike: $searchString}}) {
			aggregate {
			count
			}
		}
	}
`;


export const DeleteContactUs = gql`
	mutation DeleteContactUs($contactUsId: Int!) {
		delete_contact_us(where: {id: {_eq: $contactUsId}}) {
			affected_rows
		}
	}
`;