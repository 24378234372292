import { CircularProgress, Snackbar } from "@material-ui/core";
import firebase from "../../FirebaseConfig";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Login.scss";
import { gql, useApolloClient } from "@apollo/client";
import Logo from "../../Assets/Images/logo.png";
import APP from '../../services/app-service';
const GLOBAL = require('../../services/global-constant');
const axios = require('axios').default;

const GetAdmin = gql`
    query GetAdmin($email: String!) {
        admin_aggregate(where: { email: { _eq: $email } }) {
            aggregate {
                count
            }
        }
    }
`;

const Login: React.FC = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showSnackBar, setSnackbar] = useState(false);
    const [message, setMessage] = useState("Update Successful");

    const history = useHistory();
    const client = useApolloClient();

    const onClickHandler = () => {
        setLoading(true);

        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.login, {
            email: email,
            password: password
    })
    .then(function (response:any) {
        console.log("login response",response);
        if(!response.data.error){
            APP.removeRouteComponentData(GLOBAL.STORAGE.ls_token);
            APP.storeRouteComponentData(GLOBAL.STORAGE.ls_token,response.data.token);
            setLoading(false);
            history.push("/dashboard/reports");
            window.location.reload();
            //history.push("/dashboard/expense");
            
        }else{
            setSnackbar(true);
            setLoading(false);
            return setMessage(
                response.data.message
            );
        }
    })
    .catch(function (error:any) {
        console.log("error",error);
    });

        //----- old login-----
        // client
        //     .query({ query: GetAdmin, variables: { email } })
        //     .then(
        //         ({
        //             data: {
        //                 admin_aggregate: { aggregate },
        //             },
        //         }) => {
        //             if (aggregate.count === 0) {
        //                 setSnackbar(true);
        //                 setLoading(false);
        //                 return setMessage("User does not exists");
        //             }
        //             firebase
        //                 .auth()
        //                 .signInWithEmailAndPassword(email, password)
        //                 .then((user) => {
        //                     client
        //                         .query({
        //                             query: GetAdmin,
        //                             variables: { email },
        //                         })
        //                         .then(
        //                             ({
        //                                 data: {
        //                                     GetAdmin: { admin_aggregate },
        //                                 },
        //                             }) => {
        //                                 if (
        //                                     admin_aggregate.aggregate.count ===
        //                                     0
        //                                 ) {
        //                                     setSnackbar(true);
        //                                     setLoading(false);

        //                                     return setMessage(
        //                                         "User does not exists"
        //                                     );
        //                                 }
        //                                 setLoading(false);
        //                                 history.push("/dashboard/expense");
        //                             }
        //                         )
        //                         .catch((error) => {
        //                             setSnackbar(true);
        //                             setLoading(false);
        //                             return setMessage(error.message);
        //                         });
        //                 })
        //                 .catch((error) => {
        //                     setLoading(false);
        //                     setSnackbar(true);
        //                     setMessage(error.message);
        //                     setLoading(false);
        //                 });
        //         }
        //     )
        //     .catch((error) => {
        //         setSnackbar(true);
        //         setLoading(false);
        //         return setMessage(error.message);
        //     });
    };

    return (
        <div className="login">
            <div className="login__main">
                <img src={Logo} className="login__logo" alt="Dahbashi Logo" />
                <p className="login__subtitle">
                    {" "}
                    <span className="login__title">Welcome</span> , login to
                    continue
                </p>
                <input
                    type="email"
                    className="login__input"
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                    }}
                    name="email"
                    aria-label="email"
                    placeholder="Login Id"
                />

                <input
                    type="password"
                    name="password"
                    value={password}
                    aria-label="password"
                    onChange={(event) => {
                        setPassword(event.target.value);
                    }}
                    placeholder="Password"
                    className="login__input"
                />

                <div
                    className="d-flex justify-content-center"
                    style={{ width: "30%" }}
                >
                    {loading ? (
                        <CircularProgress
                            size={40}
                            thickness={4.5}
                            color={"primary"}
                        />
                    ) : (
                        <button
                            type="button"
                            className="login__button"
                            disabled={!(email && password)}
                            onClick={onClickHandler}
                        >
                            LOG IN
                        </button>
                    )}
                </div>
                {showSnackBar && (
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        open={showSnackBar}
                        autoHideDuration={2000}
                        message={message}
                        onClose={() => {
                            setSnackbar(false);
                        }}
                    />
                )}
            </div>
        </div>
    );
};
export default Login;
