import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useState,useEffect } from "react";
import SnackbarContext from "../../../Context/SnackbarContext";
import SearchBox from "../../UI/SearchBox/SearchBox";
import {
    DeleteMerchant,
    GetMerchantCount,
    GetMerchants,
} from "./MerchantQuery";
import { Error } from "../Promocode/Promocode";
import { CircularProgress } from "@material-ui/core";
import Empty from "../../UI/Empty/Empty";
import "./Merchant.scss";
import { CSVLink } from "react-csv";
import DeleteModal from "../../UI/DeleteModal/DeleteModal";
import MerchantCode from "./MerchantCode/MerchantCode";
import { Pagination } from "../Report/Report";
import APP from '../../../services/app-service';
const GLOBAL = require('../../../services/global-constant');
const axios = require('axios').default;

const Merchant: React.FC = () => {
    const [searchString, setSearch] = useState<string>("%%");

    const [limit] = useState(6);
    const [offset, setOffset] = useState(0);
    const [modal, setModal] = useState<boolean>(false);

    const [merchantCodes, setMerchantCodes] = useState<any>(null);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);

    const [merchantCount, setMerchantCount] = useState<any>(null);
    const [errorCount, setErrorCount] = useState();
    const [countLoading, setCountLoading] = useState(true);

    // const { data: merchantCodes, error, loading, refetch } = useQuery(
    //     GetMerchants,
    //     {
    //         variables: {
    //             searchString: `%${searchString}%`,
    //             limit,
    //             offset,
    //         },
    //     }
    // );

    // const {
    //     data: merchantCount,
    //     error: errorCount,
    //     loading: countLoading,
    //     refetch: countRefetch,
    // } = useQuery(GetMerchantCount, {
    //     variables: {
    //         searchString: `%${searchString}%`,
    //     },
    // });

    useEffect(() => {
        refetch();
    },[offset]);

    const refetch = async () => {
        setLoading(true);
        setCountLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getMerchantCodes,{
            limit:limit,
            offset:offset,
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setMerchantCodes(response.data);
                setMerchantCount(response.data.merchants_aggregate);
            }else{
                setError(response.data.message);
            }
            setLoading(false);
            setCountLoading(false);
        })
        .catch(function (error:any) {
            console.log("error",error);
        });
	};

    if (error || errorCount) {
        return <Error error={error ? error : errorCount} />;
    }
    const callRefetch = () => {
        refetch();
        //countRefetch();
    };

    return (
        <div className="container-main">
            <MerchantCode
                isOpen={modal}
                setModal={setModal}
                refetch={callRefetch}
            />

            <div className="w-40">
                <SearchBox
                    placeholder="Search merchant"
                    onChangeSearch={(value) => {
                        setSearch(`%${value}%`);
                    }}
                />
            </div>
            <div className="promo__box">
                <div className="promo__main">
                    <div className="promo__header">
                        <p>Merchants Codes</p>
                        <button
                            type="button"
                            onClick={() => {
                                setModal(true);
                            }}
                        >
                            +Add Code
                        </button>
                    </div>

                    <MerchantTitle />
                    {loading ? (
                        <div className="spinner-container">
                            <CircularProgress size={40} thickness={4} />
                        </div>
                    ) : merchantCodes.merchants.length > 0 ? (
                        merchantCodes.merchants.map((element: any) => {
                            return (
                                <MerchantItem
                                    element={element}
                                    refetch={callRefetch}
                                    key={element.id}
                                    onClickEdit={() => {}}
                                />
                            );
                        })
                    ) : (
                        <Empty />
                    )}
                </div>

                {!loading && !countLoading && (
                    <Pagination
                        limit={limit}
                        offset={offset}
                        count={
                            merchantCount
                        }
                        changeOffset={(value: any) => setOffset(value)}
                    />
                )}
            </div>
        </div>
    );
};

export default Merchant;

const MerchantTitle: React.FC = () => {
    return (
        <div className="merchant__table">
            <p className="promo__title">Merchant</p>
            <p className="promo__title">Email</p>
            <p className="promo__title">Count</p>
            <p className="promo__title">Test</p>
        </div>
    );
};

interface MerchantItemProps {
    element: any;
    refetch: () => void;
    onClickEdit: () => void;
}

const MerchantItem: React.FC<MerchantItemProps> = ({
    element,
    refetch,
    onClickEdit,
}: MerchantItemProps) => {
    const { setSnackbar, setMessage } = useContext(SnackbarContext);

    const [deleteMerchantMutation] = useMutation(DeleteMerchant);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);

    const getCodes = (): any[] => {
        const code = element.merchant_codes.map((code: any) => {
            return [code.code];
        });
        return [["Codes"], ...code];
    };

    const deleteMerchant = () => {
        setLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.deleteMerchant,{
            merchantId: element.id,
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setMessage("Merchant Question Deleted  Successfully");
                setModal(false);
                refetch();
            }else{
                setMessage("Some Unknown Error Occurred");
                setLoading(false);
                return setSnackbar(true);
            }
        })
        .catch(function (error:any) {
            setMessage(error);
            setSnackbar(true);
            setLoading(false);
        });

        // deleteMerchantMutation({
        //     variables: {
        //         merchantId: element.id,
        //     },
        // })
        //     .then(({ data: { delete_merchants: codes } }: any) => {
        //         if (codes.affected_rows > 0) {
        //             setMessage("Merchant Question Deleted  Successfully");
        //             setModal(false);
        //             refetch();
        //         } else {
        //             setMessage("Some Unknown Error Occurred");
        //             setLoading(false);
        //             return setSnackbar(true);
        //         }
        //     })
        //     .catch((error) => {
        //         setMessage(error.message);
        //         setSnackbar(true);
        //         setLoading(false);
        //     });
    };

    return (
        <div className="merchant__table">
            <DeleteModal
                setModal={setModal}
                loading={loading}
                modal={modal}
                onClickDelete={deleteMerchant}
            />

            <p className="promo__item">{element.name}</p>
            <p className="promo__item">{element.email}</p>
            <p className="promo__item">
                {element.merchant_codes_aggregate}
            </p>

            <p className="promo__item">{element.test.name}</p>
            <div className="merchant__actions">
                <button
                    className="btn-list"
                    onClick={() => {
                        setModal(true);
                    }}
                >
                    Delete
                </button>

                <CSVLink
                    filename={`${element.name} Codes.csv`}
                    className="btn-list"
                    data={getCodes()}
                >
                    Download
                </CSVLink>
            </div>
        </div>
    );
};
