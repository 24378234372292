import { gql } from "@apollo/client";

export const getPromocodes = gql`
	query GetPromocodes($searchString: String!,  $limit: Int!, $offset: Int!) {
		promo_codes(where: {promoCode: {_ilike: $searchString}}, order_by: {createdAt: asc}, 	limit: $limit,
      		offset: $offset,) {
			associate
			offer
			promoCode
			isDeleted
			id
			totalPromoCount: purchases_aggregate {
				aggregate {
					count
				}
			}
			monthlyPromoCount: purchases_aggregate(where: {isCalculated: {_eq: false}}) {
				aggregate {
					count
				}
			}
			totalMerchantCount: merchants {
				merchant_codes_aggregate {
					aggregate {
					count
					}
				}
			}
			monthlyMerchantCount: merchants {
				merchant_codes_aggregate(where: {isCalculated: {_eq: false}}) {
					aggregate {
						count
					}
				}
			}
		}
	}
`;



export const togglePromoCodeIsDeleted = gql`
	mutation TogglePromoCodeIsDeleted($id: Int!, $isDeleted: Boolean!) {
		update_promo_codes(where: {id: {_eq: $id}}, _set: {isDeleted: $isDeleted}) {
			affected_rows
		}
	}
`;


export const UpdatePromoCode = gql`
	mutation UpdatePromoCode($id: Int!, $associate: String!, $offer: Int!, $promoCode: String!) {
		update_promo_codes(where: {id: {_eq: $id}}, _set: {associate: $associate, offer: $offer, promoCode: $promoCode}) {
			affected_rows
		}
	}
`;




export const CreatePromoCode = gql`
	mutation CreatePromoCode($associate: String!, $offer: Int!, $promoCode: String!) {
		insert_promo_codes(objects: {associate: $associate, offer: $offer, promoCode: $promoCode}) {
			affected_rows
		}
	}
`;



export const GetPromocodeCodes = gql`
	query GetPromocodeCodes($searchString: String!) {
		promo_codes_aggregate(where: { isDeleted: { _eq: false }, promoCode: { _ilike: $searchString } }, ) {
			aggregate {
				count
			}
		}
	}
`;


export const ResetCount = gql`
	mutation ResetCount($promoCodeId: Int!) {
		update_purchases(where: {promoCodeId: {_eq: $promoCodeId}}, _set: {isCalculated: true}) {
			affected_rows
		}
		update_merchant_codes(where: {merchant: {promoCodeId: {_eq: $promoCodeId}}}, , _set: {isCalculated: true}) {
			affected_rows
		}
	}
`;
