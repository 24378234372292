import React from "react";
import "./ContactUsDetail.scss";
import ReactModal from "react-modal";
import close from "../../../../Assets/Images/cross.svg";

interface PromoEditProps {
    isOpen: boolean;
    editData: any;
    setEditData: (element: any | null) => void;
    setModal: (value: boolean) => void;
    refetch: () => void;
}

const ContactUsDetail: React.FC<PromoEditProps> = ({
    isOpen,
    editData,
    setEditData,
    setModal,
    refetch,
    ...props
}) => {
    return (
        <ReactModal
            className="add__modal Modal__main"
            onRequestClose={() => {
                setModal(false);
            }}
            overlayClassName="Overlay"
            isOpen={isOpen}
        >
            <div className="close">
                <img
                    src={close}
                    alt="Close"
                    className="close__img close__img-user"
                    onClick={() => {
                        setModal(false);
                    }}
                />
            </div>
            <div className="add__modal__content">
                <p className="add__modal__header">Contact Us</p>
                {editData && (
                    <div className="contact__detail">
                        <p className="contact__label">Name</p>
                        <p className="contact__title">{editData.name}</p>
                        <p className="contact__label">Email</p>
                        <p className="contact__title">{editData.email}</p>
                        <p className="contact__label">Phone</p>
                        <p className="contact__title">{editData.phoneNumber}</p>
                        <p className="contact__label">Query</p>
                        <p className="contact__title">{editData.query}</p>
                        <p className="contact__label">Subject</p>
                        <p className="contact__title">{editData.subject}</p>
                        <p className="contact__label">Reference</p>
                        <p className="contact__title">{editData.reference}</p>
                    </div>
                )}
            </div>
        </ReactModal>
    );
};

export default ContactUsDetail;
