/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const filter = createFilterOptions();

interface SearchProps {
    label: string;
    options: any[];
    value: string;
    name?: string;
    onChangeSelect: (value: any) => void;
}

const useStyles = makeStyles((theme) => ({
    textField: {
        backgroundColor: "white",
        padding: "0",
        outline: "none",
        lineHeight: "normal",
        borderRadius: "none",
    },
}));

const Search: React.FC<SearchProps> = ({
    label,
    options,
    onChangeSelect,
    value,
    name
}: SearchProps) => {
    const classes = useStyles();

    return (
        <div>
            {label && <p className="label">{label}</p>}
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    onChangeSelect(newValue);
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={options}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                }}
                renderOption={(option) => option.name}
                style={{ width: "100%", height: "2rem" }}
                freeSolo
                renderInput={(params) => (
                    <TextField
                        className={classes.textField + " input-dangerous"}
                        {...params}
                        InputLabelProps={{ shrink: false }}
                        placeholder={label}
                        variant={"outlined"}
                        size={"small"}
                    />
                )}
            />
        </div>
    );
};

export default Search;

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
