import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import React, { useContext, useState,useEffect } from "react";
import SnackbarContext from "../../../Context/SnackbarContext";
import DeleteModal from "../../UI/DeleteModal/DeleteModal";
import Empty from "../../UI/Empty/Empty";
import SearchBox from "../../UI/SearchBox/SearchBox";
import { Pagination } from "../Report/Report";
import "./Promocode.scss";
import PromoEdit from "./PromoEdit/PromoEdit";
import {
    GetPromocodeCodes,
    getPromocodes,
    ResetCount,
    togglePromoCodeIsDeleted,
} from "./PromoQuery";
import APP from '../../../services/app-service';
const GLOBAL = require('../../../services/global-constant');
const axios = require('axios').default;

const Promocode: React.FC = () => {
    const [codeSearch, setCodeSearch] = useState<string>("");
    const [editData, setEditData] = useState<any>(null);
    const [addModal, setAddModal] = useState<boolean>(false);
    const [limit] = useState(7);
    const [offset, setOffset] = useState(0);

    // const { setSnackbar, setMessage } = useContext(SnackbarContext);

    const [promoCodes, setPromoCodes] = useState<any>(null);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);

    const [promoCodeCount, setPromoCodeCount] = useState<any>(null);
    const [errorCount, setErrorCount] = useState();
    const [countLoading, setCountLoading] = useState(true);

    useEffect(() => {
        console.log("sdsd");
        refetch();
    },[offset]);

    const refetch = async () => {
        console.log("sdsd");
        setLoading(true);
        setCountLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getPromoCodes,{
            limit:limit,
            offset:offset,
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setPromoCodes(response.data);
                setPromoCodeCount(response.data.promoCodeCount);
            }else{
                setError(response.data.message);
            }
            setLoading(false);
            setCountLoading(false);
        })
        .catch(function (error:any) {
            console.log("error",error);
        });
	};

    // const { data: promoCodes, error, loading, refetch } = useQuery(
    //     getPromocodes,
    //     {
    //         variables: {
    //             searchString: `%${codeSearch}%`,
    //             limit,
    //             offset
    //         },
    //     }
    // );

    // const {
    //     data: promoCodeCount,
    //     error: errorCount,
    //     loading: countLoading,
    //     refetch: countRefetch,
    // } = useQuery(GetPromocodeCodes, {
    //     variables: {
    //         searchString: `%${codeSearch}%`,
    //     },
    // });

    if (error || errorCount) {
        return <Error error={error ? error : errorCount} />;
    }

    const callRefetch = () => {
        refetch();
        //countRefetch();
    };

    return (
        <div className="container-main">
            <PromoEdit
                isOpen={addModal}
                setModal={setAddModal}
                refetch={callRefetch}
                setEditData={setEditData}
                editData={editData}
            />
            <div className="w-40">
                <SearchBox
                    placeholder="Search code"
                    onChangeSearch={(value) => {
                        setCodeSearch(value);
                    }}
                />
            </div>
            <div className="promo__box">
                <div className="promo__main">
                    <div className="promo__header">
                        <p>Promo Codes</p>
                        <button
                            type="button"
                            onClick={() => {
                                setAddModal(true);
                            }}
                        >
                            +Add Code
                        </button>
                    </div>

                    <PromoTitle />
                    {loading ? (
                        <div className="spinner-container">
                            <CircularProgress size={40} thickness={4} />
                        </div>
                    ) : promoCodes.promo_codes.length > 0 ? (
                        promoCodes.promo_codes.map((element: any) => {
                            return (
                                <PromoItem
                                    element={element}
                                    refetch={callRefetch}
                                    key={element.id}
                                    onClickEdit={() => {
                                        setEditData(element);
                                        setAddModal(true);
                                    }}
                                />
                            );
                        })
                    ) : (
                        <Empty />
                    )}
                </div>

                {!loading && !countLoading && (
                    <Pagination
                        limit={limit}
                        offset={offset}
                        count={
                            promoCodeCount
                        }
                        changeOffset={(value: any) => setOffset(value)}
                    />
                )}
            </div>
        </div>
    );
};

export default Promocode;

const PromoTitle: React.FC = () => {
    return (
        <div className="promo__table">
            <p className="promo__title">Code</p>
            <p className="promo__title">Redeemed</p>
            <p className="promo__title">Monthly</p>
            <p className="promo__title">Associate</p>
            <p className="promo__title">Offer</p>
        </div>
    );
};

interface PromoItemProps {
    element: any;
    refetch: () => void;
    onClickEdit: () => void;
}

const PromoItem: React.FC<PromoItemProps> = ({
    element,
    refetch,
    onClickEdit,
}: PromoItemProps) => {
    const { setSnackbar, setMessage } = useContext(SnackbarContext);

    const [togglePromoCodeIsDeletedMutation] = useMutation(
        togglePromoCodeIsDeleted
    );

    const [resetCountMutation] = useMutation(ResetCount);

    const [loading, setLoading] = useState(false);
    const [resetLoading, setResetLoading] = useState(false);

    const [modal, setModal] = useState(false);

    const toggleIsDeleted = () => {
        setLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.togglePromoCodeIsDeletedMutation,{
                id: element.id,
                isDeleted: !element.isDeleted,
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setSnackbar(true);
                setMessage("Status updated Successfully");
                setLoading(false);
                refetch();
            }else{
                setMessage("Some Unknown Error Occurred");
                setLoading(false);
                return setSnackbar(true);
            }
        })
        .catch(function (error:any) {
            setSnackbar(true);
            setMessage(error);
        });

        // togglePromoCodeIsDeletedMutation({
        //     variables: {
        //         id: element.id,
        //         isDeleted: !element.isDeleted,
        //     },
        // })
        //     .then(({ data }) => {
        //         setSnackbar(true);
        //         setMessage("Status updated Successfully");
        //         setLoading(false);
        //         refetch();
        //     })
        //     .catch((error) => {
        //         setSnackbar(true);
        //         setMessage(error.message);
        //     });
    };

    const resetCount = () => {
        setResetLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.resetPromoCodeCount,{
            promoCodeId: element.id
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setSnackbar(true);
                setMessage("Count reset Successful");
                setResetLoading(false);
                setModal(false);
                refetch();
            }else{
                setSnackbar(true);
                setMessage(response.data.message);
            }
        })
        .catch(function (error:any) {
            setSnackbar(true);
            setMessage(error);
        });

        // resetCountMutation({
        //     variables: {
        //         promoCodeId: element.id,
        //     },
        // })
        //     .then(({ data }) => {
        //         setSnackbar(true);
        //         setMessage("Count reset Successful");
        //         setResetLoading(false);
        //         setModal(false);
        //         refetch();
        //     })
        //     .catch((error) => {
        //         setSnackbar(true);
        //         setMessage(error.message);
        //     });
    };

    const getCount = (array1: any, fieldString: string): number => {
        let count = 0;
        array1.forEach((element: any) => {
            console.log(element);
            count = count + element[fieldString];
        });

        return count;
    };

    return (
        <div className="promo__table">
            <DeleteModal
                setModal={setModal}
                loading={loading}
                modal={modal}
                buttonLabel={"Reset"}
                text={"Are you sure you want to reset?"}
                onClickDelete={resetCount}
            />
            <p className="promo__item">{element.promoCode}</p>
            <p className="promo__item">
                {element.totalPromoCount +
                    (element.totalMerchantCount.length > 0
                        ? getCount(
                              element.totalMerchantCount,
                              "merchant_codes_aggregate"
                          )
                        : 0)}
            </p>
            <p className="promo__item">
                {element.monthlyPromoCount+
                    (element.monthlyMerchantCount.length > 0
                        ? getCount(
                              element.monthlyMerchantCount,
                              "merchant_codes_aggregate"
                          )
                        : 0)}
            </p>
            <p className="promo__item">{element.associate}</p>
            <p className="promo__item">{element.offer}%</p>
            <div className="promo__actions">
                <button className="btn-list" onClick={onClickEdit}>
                    Edit
                </button>

                {resetLoading ? (
                    <div style={{ width: "4rem" }}>
                        <CircularProgress size={20} thickness={3} />
                    </div>
                ) : (
                    <button className="btn-list" onClick={() => setModal(true)}>
                        Reset
                    </button>
                )}

                {loading ? (
                    <div style={{ width: "4rem" }}>
                        <CircularProgress size={20} thickness={3} />
                    </div>
                ) : (
                    <button
                        className="btn-list"
                        onClick={() => {
                            toggleIsDeleted();
                        }}
                    >
                        {element.isDeleted ? "Enable" : "Disable"}
                    </button>
                )}
            </div>
        </div>
    );
};

export const Error: React.FC<{ error: any }> = ({ error }) => {
    const { setSnackbar, setMessage } = useContext(SnackbarContext);

    setSnackbar(true);
    setMessage(error.message ? error.message : "Some unknown error occurred");

    return (
        <div className="container-main">
            <div className="promo__box">
                <Empty />
            </div>
        </div>
    );
};
