import { CircularProgress } from "@material-ui/core";
import { customAlphabet } from "nanoid";
import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";
import SnackbarContext from "../../../../Context/SnackbarContext";
import close from "../../../../Assets/Images/cross.svg";
import { useMutation, useQuery } from "@apollo/client";
import Input from "../../../UI/Input/Input";
import { Error } from "../../Promocode/Promocode";
import { CreateMerchantCodes, GetMerchantCodeDetails } from "../MerchantQuery";
import Search from "../../../UI/Search/Search";
import APP from '../../../../services/app-service';
const GLOBAL = require('../../../../services/global-constant');
const axios = require('axios').default;

interface MerchantCodeProps {
    isOpen: boolean;
    setModal: (value: boolean) => void;
    refetch: () => void;
}
const MerchantCode: React.FC<MerchantCodeProps> = ({
    isOpen,
    setModal,
    refetch,
}: MerchantCodeProps) => {
    const [count, setCount] = useState<number>(1);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [test, setTest] = useState<any>(null);
    const [promoCode, setPromoCode] = useState<any>(null);

    const [mutationLoading, setMutationLoading] = useState(false);
    const [createMerchantCodes] = useMutation(CreateMerchantCodes);

    const { setSnackbar, setMessage } = useContext(SnackbarContext);
    const [promoCodeArray, setPromoCodeArray] = useState<any>();

    //const { data: details, error, loading } = useQuery(GetMerchantCodeDetails);

    const [details, setDetails] = useState<any>(null);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);

    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 6);

    useEffect(() => {

        setLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.getMerchantCodeDetails,{
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setDetails(response.data);
            }else{
                setError(response.data.message);
            }
            setLoading(false);
        })
        .catch(function (error:any) {
            console.log("error",error);
        });

    },[]);

    useEffect(() => {
        if (!loading) {
            const temp = details.promo_codes.map((element: any) => {
                return {
                    id: element.id,
                    name: element.promoCode,
                };
            });

            setPromoCodeArray([...temp]);
        }

    }, [loading, setPromoCodeArray, details]);

    if (error) {
        return <Error error={error} />;
    }

    const clearState = () => {
        setName("");
        setEmail("");
        setTest(null);
        setCount(1);
        setPromoCode(null);
        refetch();
        setModal(false);
    };

    const afterSave= async (id:any) => {
        let returnStatus = false;
        let listCodes = []; 

        for (let i = 1; i <= count; i++) {
            listCodes.push({
                code: nanoid(),
                merchantId: id
            })
        }

        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.addMerchantCodes,{
            listCodes:listCodes
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                returnStatus = true;
            }
        })
        .catch(function (error:any) {
            console.log(error);
        });
        return returnStatus;
    }

    const onSave = () => {
        setMutationLoading(true);

        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.createMerchant,{
            testId: test.id,
            count:count,
            promoCodeId: promoCode ? promoCode.id : null,
            name:name,
            email:email
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error ){
                afterSave(response.data.merchantId).then(()=>{
                    setSnackbar(true);
                    setMessage(
                        `Merchants inserted successfully`
                    );
                    setMutationLoading(false);
                    clearState();
                });
            }else{
                setSnackbar(true);
                setMessage("Some Unknown Error Occurred");
                setMutationLoading(false);
                return setSnackbar(true);
            }
        })
        .catch(function (error:any) {
            setMessage(error.message);
            setSnackbar(true);
            setMutationLoading(false);
        });

        // createMerchantCodes({
        //     variables: {
        //         testId: test.id,
        //         count,
        //         promoCodeId: promoCode ? promoCode.id : null,
        //         name,
        //         email,
        //     },
        // })
        //     .then(({ data: { createMerchantCodes: questions } }: any) => {
        //         console.log(questions);
        //         if (questions.affectedRows > 0) {
        //             setSnackbar(true);
        //             setMessage(
        //                 `${questions.affectedRows} codes inserted successfully`
        //             );
        //             setMutationLoading(false);
        //             clearState();
        //         } else {
        //             setSnackbar(true);
        //             setMessage("Some Unknown Error Occurred");
        //             setMutationLoading(false);
        //             return setSnackbar(true);
        //         }
        //     })
        //     .catch((error) => {
        //         setMessage(error.message);
        //         setSnackbar(true);
        //         setMutationLoading(false);
        //     });
    };

    return (
        <ReactModal
            className="code__modal Modal__main"
            onRequestClose={() => {
                clearState();
                setModal(false);
            }}
            overlayClassName="Overlay"
            isOpen={isOpen}
        >
            <div className="close">
                <img
                    src={close}
                    alt="Close"
                    className="close__img close__img-user"
                    onClick={() => {
                        clearState();
                        setModal(false);
                    }}
                />
            </div>
            <div className="add__modal__content">
                <p className="add__modal__header">{"Add  Merchant codes"}</p>
                {loading ? (
                    <CircularProgress size={40} thickness={4} />
                ) : (
                    <div className="margin-auto w-75">
                        <Input
                            placeholder={"Name"}
                            label
                            type={"name"}
                            disabled={false}
                            value={name}
                            onChange={(event: string) => {
                                setName(event);
                            }}
                        />
                        <Input
                            placeholder={"Email"}
                            label
                            type={"email"}
                            disabled={false}
                            value={email}
                            onChange={(event: string) => {
                                setEmail(event);
                            }}
                        />

                        <Input
                            placeholder={"Count"}
                            label
                            type={"number"}
                            disabled={false}
                            value={count.toString()}
                            onChange={(event: string) => {
                                setCount(parseInt(event));
                            }}
                        />

                        <Search
                            label={"Test"}
                            value={test ? test.name : ""}
                            options={details.tests}
                            onChangeSelect={(value) => {
                                setTest(value);
                            }}
                        />

                        <Search
                            label={"Promocode"}
                            value={promoCode ? promoCode.name : ""}
                            options={promoCodeArray}
                            onChangeSelect={(value) => {
                                console.log(value);
                                setPromoCode(value);
                            }}
                        />
                    </div>
                )}

                {!mutationLoading ? (
                    <div
                        className="w-40 margin-auto"
                        style={{ marginTop: "2em" }}
                    >
                        <PrimaryButton
                            label="Save"
                            disabled={!name || !email || !test || !count}
                            loading={mutationLoading}
                            active
                            onClick={() => {
                                onSave();
                            }}
                        />
                    </div>
                ) : (
                    <div
                        className="spinner-container"
                        style={{ marginTop: "3em" }}
                    >
                        <CircularProgress size={40} thickness={4} />
                    </div>
                )}
            </div>
        </ReactModal>
    );
};

export default MerchantCode;
