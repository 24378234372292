import React, { useContext, useEffect, useState } from "react";
import "../Test.scss";
import ReactModal from "react-modal";
import "./TestPrice.scss";
import Input from "../../../UI/Input/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContext from "../../../../Context/SnackbarContext";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";
import close from "../../../../Assets/Images/cross.svg";
import { useMutation } from "@apollo/client";
import { UpdateAmountByTestId } from "../TestQuery";
import APP from '../../../../services/app-service'
const GLOBAL = require('../../../../services/global-constant');
const axios = require('axios').default;

interface PromoEditProps {
    isOpen: boolean;
    editData: any;
    setEditData: (element: any | null) => void;
    setModal: (value: boolean) => void;
    refetch: () => void;
    countRefetch: () => void;
}

const TestPrice: React.FC<PromoEditProps> = ({
    isOpen,
    editData,
    setEditData,
    setModal,
    refetch,
    countRefetch,
}) => {
    const [amount, setAmount] = useState<number>(0);

    const [mutationLoading, setMutationLoading] = useState(false);
    const [updateAmountByTestId] = useMutation(UpdateAmountByTestId);
    const { setSnackbar, setMessage } = useContext(SnackbarContext);

    useEffect(() => {
        if (editData) {
            setAmount(editData && editData.amount / 100);
        }
    }, [editData]);

    const clearState = () => {
        setAmount(0);
        setEditData(null);
    };

    const onEdit = () => {
        setMutationLoading(true);
        axios.post(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.updateTestAmount,{
            testId: editData.id,
            amount: amount * 100,
        },APP.getToken())
        .then(function (response:any) {
            if(!response.data.error){
                setMessage("Test Amount Updated Successfully");
                setSnackbar(true);
                refetch();
                countRefetch();
                clearState();
                setMutationLoading(false);
                return setModal(false);
            }else{
                setMessage("Some Unknown Error Occurred");
                setMutationLoading(false);
                return setSnackbar(true);
            }
        })
        .catch(function (error:any) {
            setMessage("Error");
            setSnackbar(true);
            setMutationLoading(false);
        });


        // updateAmountByTestId({
        //     variables: {
        //         testId: editData.id,
        //         amount: amount * 100,
        //     },
        // })
        //     .then(({ data: { update_tests: test } }: any) => {
        //         if (test.affected_rows > 0) {
        //             setMessage("Promo Code Updated Successfully");
        //             setSnackbar(true);
        //             refetch();
        //             countRefetch();
        //             clearState();
        //             setMutationLoading(false);
        //             return setModal(false);
        //         } else {
        //             setMessage("Some Unknown Error Occurred");
        //             setMutationLoading(false);
        //             return setSnackbar(true);
        //         }
        //     })
        //     .catch((error) => {
        //         setMessage(error.message);
        //         setSnackbar(true);
        //         setMutationLoading(false);
        //     });
    };

    return (
        <ReactModal
            className="price__modal Modal__main"
            onRequestClose={() => {
                clearState();
                setModal(false);
            }}
            overlayClassName="Overlay"
            isOpen={isOpen}
        >
            <div className="close">
                <img
                    src={close}
                    alt="Close"
                    className="close__img close__img-user"
                    onClick={() => {
                        clearState();
                        setModal(false);
                    }}
                />
            </div>
            <div className="add__modal__content">
                <p className="add__modal__header">{"Edit Amount"}</p>
                <div className="margin-auto w-75">
                    <Input
                        placeholder={"Amount"}
                        label
                        type={"number"}
                        disabled={false}
                        value={amount.toString()}
                        onChange={(event: string) => {
                            setAmount(parseInt(event));
                        }}
                    />
                </div>

                {!mutationLoading ? (
                    <div
                        className="w-40 margin-auto"
                        style={{ marginTop: "2em" }}
                    >
                        <PrimaryButton
                            label="Save"
                            disabled={!amount}
                            loading={mutationLoading}
                            active
                            onClick={() => {
                                onEdit();
                            }}
                        />
                    </div>
                ) : (
                    <div
                        className="spinner-container"
                        style={{ marginTop: "3em" }}
                    >
                        <CircularProgress size={40} thickness={4} />
                    </div>
                )}
            </div>
        </ReactModal>
    );
};

export default TestPrice;
