import React from "react";
import "./Nav.scss";
import { NavLink } from "react-router-dom";
import firebase from "../../../FirebaseConfig";
import { useHistory } from "react-router";
import Logo from "../../../Assets/Images/logo.png";
import APP from '../../../services/app-service'
const GLOBAL = require('../../../services/global-constant');
const axios = require('axios').default;

const Nav = () => {
    const history = useHistory();
    const logout = () => {

        axios.get(GLOBAL.BASE_URL+GLOBAL.ENDPOINTS.logout,APP.getToken())
        .then(function (response:any) {
            console.log("response",response);
            if(!response.data.error){
                APP.removeRouteComponentData(GLOBAL.STORAGE.ls_token);
                //setisTokenAvl(false);
                history.push('/login');
                window.location.reload();
                
            }
        })
        .catch(function (error:any) {
            console.log("error",error);
        });

        // firebase
        //     .auth()
        //     .signOut()
        //     .then((data) => {
        //         //history.push("/login");
        //     });
    };

    return (
        <div className="nav">
            <div className="nav__logo">
                <NavLink to="/dashboard">
                    <img
                        src={Logo}
                        className="nav__logo-image"
                        alt="Dahbashi Logo"
                    />
                </NavLink>
            </div>

            <div className="nav__items">
                <NavLink
                    to="/dashboard/reports"
                    activeClassName="nav__item-selected"
                >
                    <p>REPORTS</p>
                </NavLink>
                <NavLink
                    to="/dashboard/tests"
                    activeClassName="nav__item-selected"
                >
                    <p>TESTS</p>
                </NavLink>
                <NavLink
                    to="/dashboard/users"
                    activeClassName="nav__item-selected"
                >
                    <p>USERS</p>
                </NavLink>
                <NavLink
                    to="/dashboard/promocodes"
                    activeClassName="nav__item-selected"
                >
                    <p>PROMO CODES</p>
                </NavLink>
                <NavLink
                    to="/dashboard/merchants"
                    activeClassName="nav__item-selected"
                >
                    <p>MERCHANTS</p>
                </NavLink>

                <NavLink
                    to="/dashboard/contact-us"
                    activeClassName="nav__item-selected"
                >
                    <p>Contact Us</p>
                </NavLink>
            </div>

            <p className="nav__sign-out" onClick={logout}>
                sign out
            </p>
        </div>
    );
};

export default Nav;
